import {Measurement, PlotProps} from "./data_types";
import {COLORS, LIMITS} from "./config";
import Chart from "react-apexcharts";

import React, {useState} from "react";


export function WeatherLinePlot({weather_data, width, height}: PlotProps) {
    const [dynamicScale, setDynamicScale] = useState<boolean>(false)
    console.log("Loaded data length: " + weather_data.length)
    console.log("Data: " + weather_data)

    const get_timestamp = (d: Measurement) => {return new Date(d.time_stamp)}

    // Temperature
    const get_air_temperature = (d: Measurement) => {return d.air_temperature}
    const get_water_temperature = (d: Measurement) => {return d.water_temperature}

    // Humidity
    const get_humidity = (d: Measurement) => {return d.humidity}

    // Air Pressure
    const get_air_pressure = (d: Measurement) => {return d.air_pressure}

    // Wind Speed
    const get_wind_speed = (d: Measurement) => {return d.wind_speed}
    const get_gusts_of_wind_speed = (d: Measurement) => {return d.gusts_of_wind_speed}

    // Precipitation
    const get_precipitation_amount = (d: Measurement) => {return d.precipitation_amount}

    // Radiation
    const get_solar_radiation = (d: Measurement) => {return d.solar_radiation}

    // Axis Bounds
    const minTemperature = dynamicScale ? Math.min(Math.min(...weather_data.map(get_air_temperature)), Math.min(...weather_data.map(get_water_temperature))) - 2 : LIMITS.get('temperature')?.at(0);
    const maxTemperature = dynamicScale ? Math.max(Math.max(...weather_data.map(get_air_temperature)), Math.max(...weather_data.map(get_water_temperature))) + 2 : LIMITS.get('temperature')?.at(1);

    const minAmount = dynamicScale ? Math.min(...weather_data.map(get_precipitation_amount)) + 1 : LIMITS.get('rain')?.at(0);
    const maxAmount = dynamicScale ? Math.max(...weather_data.map(get_precipitation_amount)) + 1 : LIMITS.get('rain')?.at(1);

    const minSpeed = dynamicScale ? Math.min(Math.min(...weather_data.map(get_gusts_of_wind_speed)), Math.min(...weather_data.map(get_wind_speed))) - 1 : LIMITS.get('wind_speed')?.at(0);
    const maxSpeed = dynamicScale ? Math.max(Math.max(...weather_data.map(get_gusts_of_wind_speed)), Math.max(...weather_data.map(get_wind_speed))) + 1 : LIMITS.get('wind_speed')?.at(1);

    const minPressure = dynamicScale ? Math.min(...weather_data.map(get_air_pressure))-5 : LIMITS.get('air_pressure')?.at(0);
    const maxPressure = dynamicScale ? Math.max(...weather_data.map(get_air_pressure))+5 : LIMITS.get('air_pressure')?.at(1);

    const minDirection = LIMITS.get('wind_direction')?.at(0);
    const maxDirection = LIMITS.get('wind_direction')?.at(1);

    const minEnergy = dynamicScale ? Math.max(Math.min(...weather_data.map(get_solar_radiation))-5, 0) : LIMITS.get('solar')?.at(0);
    const maxEnergy = dynamicScale ? Math.min(...weather_data.map(get_solar_radiation))+5 : LIMITS.get('solar')?.at(1);

    const minHumidity = dynamicScale ? Math.min(...weather_data.map(get_humidity))-5 : LIMITS.get('humidity')?.at(0);
    const maxHumidity = dynamicScale ? Math.max(...weather_data.map(get_humidity))+5 : LIMITS.get('humidity')?.at(1);

    var series = [];
    var yaxis = [];

    var strokeDashArray = [];

    if (weather_data[0].air_temperature !== null) {
        series.push(
            {
                name: 'Air Temperature',
                data: [...weather_data.map((x) => {
                    return x.air_temperature;
                })],
                color: COLORS.get("air_temperature")
            }
        );
        yaxis.push(
            {
                title: {
                    text: "Temperature [°C]"
                },
                seriesName: 'Temperature',
                axisTicks: {
                    show: true
                },
                axisBorder: {
                    show: true,
                },
                min: minTemperature,
                max: maxTemperature,
                decimalsInFloat: 1,
                show: true
            }
        );
        strokeDashArray.push(0)
    }

    if (weather_data[0].water_temperature !== null) {
        series.push(
            {
                name: 'Water Temperature',
                data: [...weather_data.map((x) => {
                    return x.water_temperature
                })],
                color: COLORS.get("water_temperature")
            }
        );
        yaxis.push(
            {
                title: {
                    text: "Temperature [°C]"
                },
                seriesName: 'Temperature',
                axisTicks: {
                    show: true
                },
                axisBorder: {
                    show: true,
                },
                min: minTemperature,
                max: maxTemperature,
                decimalsInFloat: 1,
                show: false
            }
        );
        strokeDashArray.push(0)
    }

    if (weather_data[0].dew_point !== null) {
        series.push(
            {
                name: 'Dew Point',
                data: [...weather_data.map((x) => {
                    return x.dew_point
                })],
                color: COLORS.get("dew_point")
            }
        );
        yaxis.push(
            {
                title: {
                    text: "Temperature [°C]"
                },
                seriesName: 'Temperature',
                axisTicks: {
                    show: true
                },
                axisBorder: {
                    show: true,
                },
                min: minTemperature,
                max: maxTemperature,
                decimalsInFloat: 1,
                show: false
            }
        );
        strokeDashArray.push(0)
    }

    if (weather_data[0].humidity !== null) {
        series.push(
            {
                name: 'Humidity',
                data: [...weather_data.map((x) => {
                    return x.humidity
                })],
                color: COLORS.get("humidity")
            }
        );
        yaxis.push(
            {
                title: {
                    text: "Humidity [%]"
                },
                seriesName: 'Humidity',
                opposite: false,
                axisBorder: {
                    show: true,
                },
                min: minHumidity,
                max: maxHumidity,
                decimalsInFloat: 0,
            }
        );
        strokeDashArray.push(0)
    }

    if (weather_data[0].air_pressure !== null) {
        series.push(
            {
                name: 'Air Pressure',
                data: [...weather_data.map((x) => {
                    return x.air_pressure
                })],
                color: COLORS.get("air_pressure")
            }
        );
        yaxis.push(
            {
                title: {
                    text: "Pressure [hPa]"
                },
                seriesName: "Air Pressure",
                axisBorder: {
                    show: true,
                },
                min: minPressure,
                max: maxPressure,
                decimalsInFloat: 0,
            }
        );
        strokeDashArray.push(0)
    }

    if (weather_data[0].wind_speed !== null) {
        series.push(
            {
                name: 'Wind Speed',
                data: [...weather_data.map((x) => {
                    return x.wind_speed
                })],
                color: COLORS.get("wind_speed")
            }
        );
        yaxis.push(
            {
                title: {
                    text: "Speed [kts]"
                },
                seriesName: 'Wind Speed',
                opposite: true,
                axisBorder: {
                    show: true,
                },
                min: minSpeed,
                max: maxSpeed,
                decimalsInFloat: 0,
                show: true
            }
        );
        strokeDashArray.push(0)
    }

    if (weather_data[0].gusts_of_wind_speed !== null) {
        series.push(
            {
                name: 'Gusts of Wind',
                data: [...weather_data.map((x) => {
                    return x.gusts_of_wind_speed
                })],
                color: COLORS.get("gusts_of_wind")
            }
        );
        yaxis.push(
            {
                title: {
                    text: "Speed [kts]"
                },
                seriesName: 'Gusts of Wind',
                opposite: true,
                axisBorder: {
                    show: true,
                },
                min: minSpeed,
                max: maxSpeed,
                decimalsInFloat: 0,
                show: false
            }
        );
        strokeDashArray.push(10)
    }

    if (weather_data[0].wind_direction !== null) {
        series.push(
            {
                name: 'Wind Direction',
                data: [...weather_data.map((x) => {
                    return x.wind_direction
                })],
                color: COLORS.get("wind_direction"),
            }
        );
        yaxis.push(
            {
                title: {
                    text: "Direction [Deg]"
                },
                seriesName: 'Wind Direction',
                opposite: true,
                axisBorder: {
                    show: true,
                },
                min: minDirection,
                max: maxDirection,
                decimalsInFloat: 0,
            }
        );
        strokeDashArray.push(3)
    }

    if (weather_data[0].precipitation_amount !== null) {
        series.push(
            {
                name: 'Rain',
                data: [...weather_data.map((x) => {
                    return x.precipitation_amount
                })],
                color: COLORS.get("rain"),
                type: 'column'
            }
        );
        yaxis.push(
            {
                title: {
                    text: "Amount [l/m²]"
                },
                seriesName: 'Rain',
                axisTicks: {
                    show: true
                },
                axisBorder: {
                    show: true,
                },
                min: minAmount,
                max: maxAmount,
                decimalsInFloat: 1,
            }
        );
        strokeDashArray.push(0)
    }

    if (weather_data[0].solar_radiation !== null) {
        series.push(
            {
                name: 'Solar Radiation',
                data: [...weather_data.map((x) => {
                    return x.solar_radiation
                })],
                color: COLORS.get("solar")
            }
        );
        yaxis.push(
            {
                title: {
                    text: "Energy [W/m²]"
                },
                seriesName: 'Solar Radiation',
                opposite: true,
                axisBorder: {
                    show: true,
                },
                min: minEnergy,
                max: maxEnergy,
                decimalsInFloat: 0,
            }
        )
        strokeDashArray.push(0)
    }

    const chartConfig = {
        options: {
            chart: {
                animations: {
                    enabled: false
                },
                events: {
                    click: function(event: any, chartContext: any, config: any) {
                        setDynamicScale(!dynamicScale)
                    }
                }
            },
            stroke: {
                width: 2,
                dashArray: strokeDashArray
            },
            xaxis: {
                categories: [...weather_data.map((d) => {
                    // return get_timestamp(d)
                    return get_timestamp(d).toLocaleString()
                })],
                // type: 'datetime',
                // min: get_timestamp(weather_data[0]).getTime()
                // labels: {
                //     format: 'dd/MM'
                // }
            },
            yaxis: yaxis,
        },
        series: series,

    }

    return (
        <div style={{ position: "relative" }}>
            <Chart
                options={chartConfig.options}
                series={chartConfig.series}
                width={width}
                height={height}
                type="line"
            />
        </div>
    )
}