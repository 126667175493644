import {DEFAULT_LANGUAGE} from "./config";

export function getLanguage(): string {
    if (navigator.language !== undefined) {
        return navigator.language;
    } else if (navigator.languages !== undefined) {
        return navigator.languages[0]
    } else {
        return DEFAULT_LANGUAGE
    }
}

export function setDateNotNull(x: Date | null, defaultValue: Date): Date {
    if (x) {
        return x;
    }
    else {
        return defaultValue
    }
}
