export const API_URL: string = 'https://dev-api-weather.swiss-appz.ch/api/v1/public/';
export const WEATHER_STATION_URL: string = API_URL + "weather_station/"
export const MEASUREMENT_URL: string = API_URL + "measurement/"
export const COLORS_ALTERNATIVE: Map<string, string> = new Map<string, string>([
    ['water_temperature', '#00ff40'],
    ['air_temperature', '#ff0000'],
    ['air_pressure', '#005b00'],
    ['rain', '#00ffff'],
    ['wind_speed', '#000000'],
    ['wind_direction', '#333333'],
    ['solar', '#ff8040'],
    ['dew_point', '#0000ff'],
    ['gusts_of_wind', '#000000'],
    ['humidity', '#f700ff']
]);

export const COLORS: Map<string, string> = new Map<string, string>([
    ['water_temperature', '#00ffd9'],
    ['air_temperature', '#d70000'],
    ['air_pressure', '#750000'],
    ['rain', '#00ffff'],
    ['wind_speed', '#000000'],
    ['wind_direction', '#333333'],
    ['solar', '#ff8040'],
    ['dew_point', '#5757fa'],
    ['gusts_of_wind', '#000000'],
    ['humidity', '#000036']
]);

export const LIMITS: Map<string, number[]> = new Map<string, [number, number]> ([
    ['temperature', [-20, 40]],
    ['speed', [0, 60]],
    ['air_temperature', [-20, 40]],
    ['water_temperature', [-20, 40]],
    ['air_pressure', [980, 1040]],
    ['rain', [0, 6]],
    ['wind_speed', [0, 60]],
    ['wind_direction', [0, 360]],
    ['solar', [0, 1200]],
    ['dew_point', [-20, 40]],
    ['gusts_of_wind', [0, 60]],
    ['humidity', [0, 100]]
]);

export const UNITS: Map<string, string> = new Map<string, string>([
    ['water_temperature', 'ºC'],
    ['air_temperature', 'ºC'],
    ['air_pressure', 'hPa'],
    ['rain', 'l/m²'],
    ['wind_speed', 'kts'],
    ['wind_direction', 'º'],
    ['solar', 'W/m²'],
    ['dew_point', 'ºC'],
    ['gusts_of_wind', 'kts']
]);

export const DEFAULT_TIME_DISTANCE_DAYS: number = 1;
export const MAX_TIME_DISTANCE_DAYS: number = 10;

export const AGGREGATION_INTERVAL_SECONDS: number = 600;
export const DEFAULT_LANGUAGE: string = 'en-US';
