import React from 'react';
import './App.css';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";

import {WeatherView} from "./WeatherView";


function App() {
  return (
    <div className="App">
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <header className="App-header">
            <h3>Weather Display</h3>
        </header>
        <WeatherView />
      </LocalizationProvider>
    </div>

  );
}

export default App;
