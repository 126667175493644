import {DEFAULT_TIME_DISTANCE_DAYS, WEATHER_STATION_URL} from "./config";
import React, {useEffect, useState} from "react";
import {WeatherStation} from "./data_types";
import Select, {SelectChangeEvent} from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import {InputLabel} from "@mui/material";
import {DateTimePicker} from "@mui/x-date-pickers";
import {WeatherPlot} from "./WeatherPlot"
import {setDateNotNull} from "./helper_functions";

export function WeatherView() {
    const defaultEndDateTime = new Date(Date.now())
    const defaultStartDateTime = new Date(Date.now())
    defaultStartDateTime.setDate(defaultEndDateTime.getDate() - DEFAULT_TIME_DISTANCE_DAYS)

    const weatherPlotWidth = window.innerWidth;
    const weatherPlotHeight = window.innerHeight;

    const [weather_stations, setWeatherStations] = useState<WeatherStation[]>([])
    const [selectedWeatherStation, setSelectedWeatherStation] = useState<string>('')
    const [endDateTime, setEndDateTime] = useState<Date>(defaultEndDateTime)
    const [startDateTime, setStartDateTime] = useState<Date>(defaultStartDateTime)

    const handleStationChange = (event: SelectChangeEvent) => {
        console.log("Value has changed to ", event.target.value)
        setSelectedWeatherStation(event.target.value)
    }

    const load_weather_stations = () => {
        fetch(WEATHER_STATION_URL)
            .then(res => res.json())
            .then(
                (result) => {
                    setWeatherStations(result)
                },
                (error) => {
                    // TODO: error handling
                }
            )
        console.log("Weather stations have been updated...")
    }

    useEffect( () => {
            load_weather_stations()
        }, [selectedWeatherStation]
    );

    let dropdown_options = weather_stations.map((weather_station) => {
        return (
            <MenuItem value={weather_station.id} key={"weather-station-selection-" + weather_station.id}>{weather_station.name + " in " + weather_station.city}</MenuItem>
        )
    })

    return (
        <div>
            <div className={ "weather-station-selector-box" }>
                <div className="weather-station-selector" id="selector-weather-station-id">
                    <FormControl variant="standard" sx={{m: 2, minWidth: "100%"}}>
                        <InputLabel id="weather-station-selector-label">
                            Weather Station
                        </InputLabel>
                        <Select
                          labelId="weather-station-simple-select-standard"
                          id="weather-station-simple-select-standard"
                          value={selectedWeatherStation}
                          onChange={handleStationChange}
                          label="Weather Station"
                        >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            {dropdown_options}
                        </Select>
                    </FormControl>
                </div>
                <div className="weather-station-date-selector-start" id="selector-start-time">
                    <DateTimePicker
                    label="Select Start Date and Time"
                    value={startDateTime}
                    onChange={(newValue: Date | null) => {setStartDateTime(setDateNotNull(newValue, startDateTime))}}
                    />
                </div>
                <div className="weather-station-date-selector-end" id="selector-end-time">
                    <DateTimePicker
                        label="Select End Date and Time"
                        value={endDateTime}
                        onChange={(newValue: Date | null) => {setEndDateTime(setDateNotNull(newValue, endDateTime))}}
                    />
                </div>
            </div>
            <div>
                <WeatherPlot uuid={selectedWeatherStation} start_time={startDateTime} end_time={endDateTime} width={weatherPlotWidth} height={weatherPlotHeight}></WeatherPlot>
            </div>
        </div>
    )
}