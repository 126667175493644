import {Measurement, WeatherPlotProps} from "./data_types";
import React, {useEffect, useState} from "react";
import {AGGREGATION_INTERVAL_SECONDS, MEASUREMENT_URL} from "./config";
import {WeatherLinePlot} from "./WeatherLinePlot";

export function WeatherPlot({ uuid, start_time, end_time, width, height }: WeatherPlotProps) {
  const [weatherData, setWeatherData] = useState<Measurement[]>([])

  // const width = bounds.width || defaultWidth
  // const height = bounds.height || defaultHeight

  useEffect(() => {
      if (!uuid) {
          setWeatherData([])
          return;
      }
      // var url = API_URL + 'station_measurements/' + uuid + '/?start_time=' + start_time.toISOString() + '&end_time=' + end_time.toISOString()
      var url = MEASUREMENT_URL + uuid + '/aggregated' + '/?start_time=' + start_time.toISOString() + '&end_time=' + end_time.toISOString() + "&aggregation_interval_seconds=" + AGGREGATION_INTERVAL_SECONDS
      fetch(url)
         .then(res => res.json())
         .then(
           (result) => {
             setWeatherData(result)
           },
           (error) => {
             // Todo: handle error
             console.log(error)
           }
         );
  }, [uuid, start_time, end_time])

  if (weatherData.length > 0) {
      return (
          <div className="weather-data-plot">
            <WeatherLinePlot weather_data={weatherData} height={height} width={width} />
          </div>
      )
  }
  else if (uuid && weatherData.length === 0) {
      return (
          <div>
              <h5>{"Weather station " + uuid + " has no data."}</h5>
          </div>
      )
  }
  else {
      return(
          <div>
            <h5>Weather station is not selected.</h5>
          </div>
      )
  }
}